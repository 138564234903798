import $http from '../utils/request';
import axios from 'axios';

export const fetchPriceTotal = async params => await $http.get('price/total', {
    params
});

export const fetchOrders = async params => await $http.get('orders', {
    params
});

export const createOrder = async params => await $http.post('order/create', params);

export const orderCancel = async params => await $http.post('order/cancel', params);

export const fetchOrder = async params => await $http.get('order/pay', {
    params
});

export const createAliPayUrl = async params => await $http.post('ali/pay', params);

export const createWeChatPayUrl = async params => await $http.post('pay/wechat', params, {
    headers: {
        'Content-Type': 'application/json',
    },
    'baseURL': '/'
});

export const createPlisioPayUrl = async params => await $http.post('pay/plisio', params, {
    headers: {
        'Content-Type': 'application/json',
    },
    'baseURL': '/'
});