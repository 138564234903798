<template>
  <div>
    <div class="main" v-show="priceDetail.version || priceDetail.buyAfterexpiredDate">
      <span class="order-tittle">{{ $t('order.tittle') }}</span>
      <div class="order-form">
        <h2>{{ $t('order.perfectOrder') }}</h2>
        <div class="package-detail">
          <div class="order-choose">
            <div class="form-item" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('order.current') }}</span>
              <div class="form-control">
                <span v-if="currentPackage.name">{{ $t(`package.${currentPackage.name}`) }}</span>
                <span v-else>{{ $t('package.NONE') }}</span>
              </div>
            </div>
            <div class="form-item" style="margin-bottom: 30px" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('order.choosePackage') }}</span>
              <div class="form-control">
                <a-radio-group v-model="orderForm.version" buttonStyle="solid" :size="locale === 'en_US' ? 'small' : 'default'">
                  <a-radio-button :value="item.name" v-for="item in packages" :key="item.name" :disabled="item.disabled || paying" @click="handlePackageChange(orderForm.version, item.name)">{{
                    $t(`package.${item.name}`)
                  }}</a-radio-button>
                </a-radio-group>
              </div>
            </div>
            <div class="form-item" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('order.chooseDuration') }}</span>
              <div class="form-control">
                <a-radio-group v-model="orderForm.duration" buttonStyle="solid" @change="getPrice(orderForm)" :disabled="durationDisabled || paying">
                  <a-radio-button :value="item.value" v-for="item in times" :key="item.value" class="time-btn"
                    >{{ item.label }}
                    <div v-if="item.value == 12" class="time-save" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ locale === 'zh_CN' ? '八折' : '80%' }}</div>
                    <div v-if="item.value == 24" class="time-save" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ locale === 'zh_CN' ? '七折' : '70%' }}</div>
                    <div v-if="item.value == 36" class="time-save" :class="locale === 'zh_CN' ? 'zh' : 'en'">{{ locale === 'zh_CN' ? '六折' : '60%' }}</div>
                  </a-radio-button>
                </a-radio-group>
                <a href="javascript:;" class="more-time" v-if="durationDisabled || paying">
                  {{ dropdownLabel }}
                </a>
                <dropdown @command="handleTime2Change" v-else>
                  <a href="javascript:;" class="more-time" :class="{ active: selectDropdown }">
                    {{ dropdownLabel }}
                    <i class="icon-angle-down"></i>
                  </a>
                  <dropdown-menu>
                    <dropdown-item v-for="item in times2" :key="item.value" :command="item.value">{{ item.label }}</dropdown-item>
                  </dropdown-menu>
                </dropdown>
              </div>
            </div>
            <div class="package-message">
              <div class="now-message" v-if="currentPackage.name">
                <span
                  >{{ locale === 'zh_CN' ? '当前套餐' : 'Current' }} - {{ $t(`package.${currentPackage.name}`) }}, {{ locale === 'zh_CN' ? '到期时间' : 'Expire date' }} -
                  {{ formatDate(priceDetail.expiredDate) }}</span
                ><span v-if="isUpgrade"
                  >, {{ locale === 'zh_CN' ? '升级需补' : 'Upgrade cost' }} - <span style="color: #351d06">{{ currencyMap[orderForm.currency] }}{{ returnFloat(priceDetail.upPrice) }}</span></span
                >
              </div>
              <div class="renewal-message">
                <span>
                  <span v-if="currentPackage.name">{{ locale === 'zh_CN' ? '续费后到期时间' : 'Expire date after renewal' }} - {{ formatDate(priceDetail.buyAfterexpiredDate) }}</span>
                  <span v-else>{{ locale === 'zh_CN' ? '到期时间' : 'Expire date' }} - {{ formatDate(priceDetail.buyAfterexpiredDate) }}</span>
                </span>
              </div>
            </div>
            <!-- <div class="form-item" v-if="orderForm.version !== 'FREE'" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('order.paymentMethod') }}</span>
              <div class="form-control">
                <a-radio-group v-model="orderForm.currency" buttonStyle="solid" @change="getPrice(orderForm)">
                  <a-radio-button :value="currency.value" v-for="currency in currencies" :key="currency.value">{{ currency.label }}</a-radio-button>
                </a-radio-group>
                <span class="real-time-rate" v-if="orderForm.currency === 'ETH'"> {{ $t('order.realTimeRate') }}: 1ETH= <Numberic :data="1 / priceDetail.rate" :fold="false" />USD </span>
                <span class="real-time-rate" v-else-if="orderForm.currency === 'CNY'"> {{ $t('order.realTimeRate') }}: 1USD= <Numberic :data="priceDetail.rate" :fold="false" />CNY </span>
              </div>
            </div> -->
          </div>
          <div class="package-info">
            <table class="custom-table" v-if="selectedPackage.name">
              <thead>
                <tr>
                  <th></th>
                  <th v-if="!isEmpty(currentPackage)">{{ $t(`package.${currentPackage.name}`) }}</th>
                  <th>{{ $t(`package.${selectedPackage.name}`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('package.openData') }}</td>
                  <td v-if="!isEmpty(currentPackage)">{{ $t('common.all') }}</td>
                  <td>{{ $t('common.all') }}</td>
                </tr>
                <tr>
                  <td>Rest API</td>
                  <td v-if="!isEmpty(currentPackage)">
                    <i :class="currentPackage.isSupportRest ? 'icon-ok' : 'icon-cancel-1'"></i>
                  </td>
                  <td>
                    <i :class="selectedPackage.isSupportRest ? 'icon-ok' : 'icon-cancel-1'"></i>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('package.rateLimiting') }}</td>
                  <td v-if="!isEmpty(currentPackage)">{{ $t('order.callsPerMonth', [currentPackage.request]) }}</td>
                  <td>{{ $t('order.callsPerMonth', [selectedPackage.request]) }}</td>
                </tr>
                <tr>
                  <td>QPS</td>
                  <td v-if="!isEmpty(currentPackage)">{{ $t('package.qpsShort', { num: currentPackage.qps }) }}</td>
                  <td>{{ $t('package.qpsShort', { num: selectedPackage.qps }) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('package.wsConnections') }}</td>
                  <td v-if="!isEmpty(currentPackage)">
                    <i class="icon-cancel-1" v-if="currentPackage.ws === 0"></i>
                    <template v-else>{{ currentPackage.ws }}</template>
                  </td>
                  <td>
                    <i class="icon-cancel-1" v-if="selectedPackage.ws === 0"></i>
                    <template v-else>{{ selectedPackage.ws }}</template>
                  </td>
                </tr>
                <tr>
                  <td>Web hook(Beta)</td>
                  <td v-if="!isEmpty(currentPackage)">
                    <i :class="currentPackage.wh ? 'icon-ok' : 'icon-cancel-1'"></i>
                  </td>
                  <td>
                    <i :class="selectedPackage.wh ? 'icon-ok' : 'icon-cancel-1'"></i>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('package.customerSupport') }}</td>
                  <td v-if="!isEmpty(currentPackage)">
                    <template v-if="currentPackage.support === 'email'">{{ $t('package.email') }}</template>
                    <template v-else-if="currentPackage.support === 'email,phone'">{{ $t('package.phoneAndEmailSupport') }}</template>
                  </td>
                  <td>
                    <template v-if="selectedPackage.support === 'email'">{{ $t('package.email') }}</template>
                    <template v-else-if="selectedPackage.support === 'email,phone'">{{ $t('package.phoneAndEmailSupport') }}</template>
                  </td>
                </tr>
                <!-- <tr>
                  <td>{{ $t('package.price') }}</td>
                  <td v-if="currentPackage.price && !isEmpty(currentPackage)">${{ currentPackage.price[0] }}/{{ $t('time.month', { num: '' }) }}</td>
                  <td v-if="selectedPackage.price">${{ selectedPackage.price[0] }}/{{ $t('time.month', { num: '' }) }}</td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-bottom">
          <div class="order-price-info">
            <div class="form-item" v-if="orderForm.version != 'FREE'" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('package.amount') }}</span>
              <div class="form-control">
                <div class="num package-num">
                  <!-- <Numberic :data="priceDetail.packagePrice.toFixed(2)" :fold="false" prefix="¥"></Numberic> -->
                  <span>{{ currencyMap[this.orderForm.currency] }}{{ returnFloat(priceDetail.packagePrice) }}</span>

                  <!-- <Numberic class="green" :data="returnFloat(priceDetail.discPrice)" :fold="false">
                    <template slot="prefix">
                      <i18n path="order.discount">
                        <i>{{ discount[orderForm.duration] || 8 | formatDiscount(locale) }}</i>
                      </i18n>
                      <em style="font-size: 14px"> {{ $t('order.save') }}</em> ¥
                    </template>
                  </Numberic> -->
                  <div style="margin-left: 5px">
                    <i18n path="order.discount" class="green">
                      <i>{{ discount[orderForm.duration] || 8 | formatDiscount(locale) }}</i>
                    </i18n>
                    <em style="font-size: 14px"> {{ $t('order.save') }}</em
                    ><span class="green"> {{ currencyMap[this.orderForm.currency] }}{{ returnFloat(priceDetail.discPrice) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="isUpgrade">
              <div class="form-item" :class="{ en: locale === 'en_US' }">
                <span class="label">{{ $t('package.upgradeSpread') }}</span>
                <div class="form-control">
                  <!-- <Numberic class="num" :data="returnFloat(priceDetail.upPrice)" :fold="false">
                    <template slot="prefix">¥</template>
                  </Numberic> -->
                  <span class="num">{{ currencyMap[this.orderForm.currency] }}{{ returnFloat(priceDetail.upPrice) }}</span>
                </div>
              </div>
              <!-- <div class="form-item" :class="{ en: locale === 'en_US' }">
                <span class="label">{{ $t('order.payable') }}</span>
                <div class="form-control">
                  <Numberic class="num" :data="priceDetail.usdPrice" :fold="false">
                    <template slot="prefix">¥</template>
                  </Numberic>
                </div>
              </div> -->
            </template>
            <div class="form-item" :class="{ en: locale === 'en_US' }">
              <span class="label">{{ $t('order.costEffective') }}</span>
              <div class="form-control">
                <!-- <Numberic class="num middle text-red" :data="returnFloat(priceDetail.payPrice)" :fold="false">
                  <template slot="suffix">{{ priceDetail.currency ? priceDetail.currency : ' CNY' }}</template>
                </Numberic> -->
                <span class="num middle text-red">{{ currencyMap[this.orderForm.currency] }}{{ returnFloat(priceDetail.payPrice) }} {{ orderForm.currency }}</span>
              </div>
            </div>
            <div class="form-item" :class="{ en: locale === 'en_US' }">
              <button @click="submitOrder" class="button" style="margin-left: 30px; text-transform: none" :disabled="buying" v-if="!paying">
                {{ $t('order.buynow') }}<a-spin v-if="buying"></a-spin>
              </button>
              <!-- <button @click="submitOrder" class="button" style="margin-left: 30px" :disabled="buying">生成订单<a-spin v-if="buying"></a-spin></button>
              <span style="margin-left: 10px">点击后自动为您生成订单并跳转至电报，请在60分钟内联系电报Telegram客服完成支付，激活订单即可使用</span> -->
              <template v-if="paying">
                <!-- <button
                  class="button button-plain"
                  style="margin-left: 30px"
                  @click="
                    handleAliPay({
                      money: priceDetail.payPrice,
                      orderId: orderId,
                      version: orderForm.version,
                      currency: orderForm.currency,
                    })
                  "
                >
                  {{ $t('order.paymentFailed') }}
                </button> -->
                <button
                  v-if="payType === 'wechat'"
                  class="button button-plain"
                  style="margin-left: 30px"
                  @click="
                    handleWeChatPay({
                      currency: orderForm.currency,
                      amount: priceDetail.payPrice,
                      orderId: orderId,
                    })
                  "
                >
                  {{ $t('order.paymentFailed') }}
                </button>
                <button v-else-if="payType === 'plisio' && plisioPayUrl" class="button button-plain" style="margin-left: 30px" @click="handleOpenPage(plisioPayUrl)">
                  {{ $t('order.paymentFailed') }}
                </button>
                <router-link :to="{ name: 'account-name', params: { name: 'dashboard' } }" class="button">{{ $t('order.paymentSuccessful') }}</router-link>
              </template>
              <!-- <a-popover>
                <template slot="content">
                  <img src="../assets/images/wecom-qrcode.png" class="cs-qrcode" />
                </template>
                <span class="cs-tips">如需用U支付或了解更多，请联系客服</span>
              </a-popover> -->
              <span class="cs-tips">{{ $t('order.helpTip') }}</span>
            </div>
            <a-spin v-if="loadingPrice"></a-spin>
          </div>
        </div>
      </div>
      <a-modal v-model="QRCodeVisible" :footer="null" class="qrcode-dialog" :width="400">
        <div class="qrcode-content">
          <img src="../assets/images/wechatpay-logo.png" class="wechat-log" />
          <img :src="QRCodeImg" class="qrcode-img" />
          <img src="../assets/images/wechatpay-tips.png" class="wechat-tips" />
        </div>
      </a-modal>
    </div>
    <div v-show="!priceDetail.version && !priceDetail.buyAfterexpiredDate">
      <a-spin size="large" style="margin-top:100px;"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Radio from 'ant-design-vue/lib/radio';
import { createWeChatPayUrl, createPlisioPayUrl, createAliPayUrl, createOrder, fetchPriceTotal } from 'services/orders';
import { isEmpty } from '../utils/util';

const level = {
  FREE: 1,
  BASIC: 2,
  PROFESSION: 3,
  ENTERPRISE: 4,
  ULTIMATE: 5,
};
const LANG = {
  zh_CN: {
    year: num => `${num}年`,
    month: num => `${num}个月`,
  },
  en_US: {
    year: num => `${num}Y`,
    month: num => `${num}M`,
  },
};
function returnFloat(value) {
  var value = Math.round(parseFloat(value) * 100) / 100;
  var xsd = value.toString().split('.');
  if (xsd.length == 1) {
    value = value.toString() + '.00';
    return value;
  }
  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      value = value.toString() + '0';
    }
    return value;
  }
}
export default {
  name: 'order-form',
  components: {
    [Radio.name]: Radio,
    [Radio.Group.name]: Radio.Group,
    [Radio.Button.name]: Radio.Button,
  },
  data() {
    return {
      orderForm: {
        version: this.$route.query.package,
        duration: Number(this.$route.query.duration) || '',
        currency: this.locale === 'zh_CN' ? 'CNY' : 'USD',
      },
      priceDetail: {},
      discount: {
        12: 8,
        24: 7,
        36: 6,
      },
      loadingPrice: false,
      durationDisabled: false,
      buying: false,
      paying: false,
      orderId: '',
      selectedTime: 12,
      QRCodeVisible: false,
      QRCodeImg: '',
      returnFloat,
      currencyMap: {
        CNY: '¥',
        USD: '$',
      },
      plisioPayUrl: '',
    };
  },
  filters: {
    formatDiscount(value, locale) {
      if (locale === 'zh_CN') {
        return value;
      } else {
        return `${((10 - value) / 10) * 100}%`;
      }
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'packages', 'locale']),
    times() {
      return [3, 6, 12, 24, 36].map(item => {
        let label = item >= 12 ? LANG[this.locale].year(item / 12) : LANG[this.locale].month(item);
        return {
          label,
          value: item,
        };
      });
    },
    times2() {
      return [1, 2, 4, 5, 7, 8, 9, 10, 11].map(item => ({
        label: LANG[this.locale].month(item),
        value: item,
      }));
    },
    currentPackage() {
      if (this.packages.length === 0) return {};
      return this.packages.find(item => item.name === this.userInfo.pricePackage) || {};
    },
    selectedPackage() {
      if (this.packages.length === 0) return {};
      return this.packages.find(item => item.name === this.orderForm.version) || {};
    },
    dropdownLabel() {
      let item = this.times2.find(item => item.value === this.orderForm.duration);
      return item ? item.label : this.$t('common.more');
    },
    selectDropdown() {
      let item = this.times2.find(item => item.value === this.orderForm.duration);
      return !!item;
    },
    isUpgrade() {
      return level[this.userInfo.pricePackage] < level[this.orderForm.version];
    },
    isDown() {
      return level[this.userInfo.pricePackage] > level[this.$route.query.package];
    },
    payType() {
      return this.locale === 'zh_CN' ? 'wechat' : 'plisio';
    },
    // currencies() {
    //   return [
    //     {
    //       label: this.$t('order.aliPay'),
    //       value: 'CNY',
    //     },
    //   ];
    // },
  },
  mounted() {
    // this.handlePackageChange();
    this.orderForm.currency = this.locale === 'zh_CN' ? 'CNY' : 'USD';
    if (!this.isDown) {
      this.orderForm.version = this.$route.query.package;
    } else {
      this.orderForm.version = this.userInfo.pricePackage;
    }
    // if (this.isDown) {
    //   this.orderForm.version = this.userInfo.pricePackage;
    // } else if (this.$route.query.package == 'FREE' && !this.userInfo.freeAvailable) {
    //   this.orderForm.version = 'BASIC';
    //   this.orderForm.duration = 3;
    // } else {
    //   this.orderForm.version = this.$route.query.package;
    // }
    if (this.orderForm.version === 'FREE') {
      this.orderForm.duration = 1;
      this.durationDisabled = true;
    } else {
      this.durationDisabled = false;
    }
    this.getPrice(this.orderForm);
  },
  methods: {
    isEmpty(target) {
      return isEmpty(target);
    },
    getPrice(params) {
      this.loadingPrice = true;
      fetchPriceTotal(params)
        .then(res => {
          this.loadingPrice = false;
          this.priceDetail = res.data;
        })
        .catch(err => {
          this.$router.push({ name: 'login' });
        });
    },
    handleTime2Change(value) {
      this.orderForm.duration = value;
      this.getPrice(this.orderForm);
    },
    submitOrder() {
      if (this.orderForm.version === 'FREE') {
        if (this.userInfo.pricePackage == 'FREE' && !this.userInfo.isExpired) {
          this.$error({
            title: this.$t('order.freeIsUsingTip'),
          });
          return;
        } else if (this.userInfo.lastVersion == 'FREE' && this.userInfo.isExpired) {
          this.$error({
            title: this.$t('order.freeIsOutTip'),
          });
          return;
        } else if (this.userInfo.lastVersion != 'FREE' && this.userInfo.isExpired) {
          this.$error({
            title: this.$t('order.freeOnlyNewTip'),
          });
          return;
        }
      }
      this.buying = true;
      createOrder({
        ...this.orderForm,
        exchangeRate: this.priceDetail.rate,
      })
        .then(res => {
          this.buying = false;
          if (res.code === 0) {
            this.orderId = res.data;
            if (this.orderForm.version === 'FREE') {
              let modal = this.$success({
                content: this.$t('order.buySuccess'),
              });
              setTimeout(() => {
                this.$store.dispatch('initAuth');
                modal.destroy();
                this.$router.push({ name: 'account-name', params: { name: 'dashboard' } });
              }, 2000);
            } else {
              if (this.orderForm.currency === 'CNY') {
                // this.handleAliPay({
                //   money: this.priceDetail.payPrice,
                //   orderId: this.orderId,
                //   version: this.orderForm.version,
                //   currency: this.orderForm.currency,
                // });
                this.handleWeChatPay({
                  currency: this.orderForm.currency,
                  amount: this.priceDetail.payPrice,
                  orderId: this.orderId,
                });
              } else {
                // this.$router.push({ name: 'payment', query: { order: res.data } });
                this.handlePlisioPay({
                  currency: this.orderForm.currency,
                  amount: this.priceDetail.payPrice,
                  orderId: this.orderId,
                });
              }
            }
          }
        })
        .catch(err => {
          let errorKey = err.response.data.errorKey;
          let title = this.$t(`error['${errorKey}']`);
          // let title='您有未支付的订单，请联系Telegram客服完成支付或者取消！';
          this.buying = false;
          if (errorKey === 'error.orders.has.unpaid') {
            this.$confirm({
              title,
              okText: this.$t('order.process'),
              cancelText: this.$t('common.cancel'),
              onOk: () => {
                this.$router.push({ name: 'account-name', params: { name: 'order' } });
              },
            });
            return;
          }
          this.$error({
            title,
          });
        });
    },
    // handleAliPay(params) {
    //   createAliPayUrl(params)
    //     .then(res => {
    //       if (res.code === 0) {
    //         let divForm = document.getElementsByTagName('divform');
    //         if (divForm.length) {
    //           document.body.removeChild(divForm[0]);
    //         }
    //         const div = document.createElement('divform');
    //         div.innerHTML = res.data; // data就是接口返回的form 表单字符串
    //         document.body.appendChild(div);
    //         document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
    //         document.forms[0].submit();
    //         this.paying = true;
    //       }
    //     })
    //     .catch(err => {
    //       this.$error({
    //         title: this.$t(`error['${err.response.data.errorKey}']`),
    //       });
    //     });
    //   // window.open('https://t.me/mifengcha_cs','_blank')
    // },
    handleWeChatPay(params) {
      createWeChatPayUrl(params)
        .then(res => {
          if (res.code === 0) {
            this.QRCodeImg = 'data:image/jpeg;base64,' + res.data;
            this.QRCodeVisible = true;
            this.paying = true;
          } else {
            this.$error({
              title: '微信支付二维码生成失败',
            });
          }
        })
        .catch(err => {
          this.$error({
            title: '微信支付二维码生成失败',
          });
        });
    },
    handlePlisioPay(params) {
      createPlisioPayUrl(params)
        .then(res => {
          if (res.code === 0 && res.data) {
            window.open(res.data, '_blank');
            this.plisioPayUrl = res.data;
            this.paying = true;
          } else {
            this.$error({
              title: 'Pay Error!',
            });
          }
        })
        .catch(err => {
          this.$error({
            title: 'Pay Error!',
          });
        });
    },
    handlePackageChange(oldV, newV) {
      this.orderForm.version = newV;
      if (oldV != 'FREE') {
        this.selectedTime = this.orderForm.duration;
      } else {
        // this.selectedTime=12;
      }
      if (newV === 'FREE') {
        this.orderForm.duration = 1;
        this.durationDisabled = true;
      } else {
        this.orderForm.duration = this.selectedTime;
        this.durationDisabled = false;
      }
      this.getPrice(this.orderForm);
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear();
      var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      //   var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      //   var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      //   var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      if (this.locale === 'zh_CN') return YY + '年' + MM + '月' + DD + '日';
      else return YY + '/' + MM + '/' + DD;
    },
    handleOpenPage(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
@import '~ant-design-vue/lib/radio/style/index.css';
.main {
  background-image: url('../assets/images/dark-banner.png');
  background-size: 100% 747px;
  background-repeat: no-repeat;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .order-tittle {
    margin-top: 135px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.order-form {
  width: @container;
  margin: 60px auto 100px auto;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: white;
  h2 {
    font-size: 20px;
    margin-bottom: 50px;
  }
}
.time-btn {
  position: relative;
  .time-save {
    position: absolute;
    top: -18px;
    right: -1px;
    background-image: url('../assets/images/order/save-lable.png');
    width: 34px;
    height: 22px;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}
.package-message {
  margin-left: 30px;
  color: #888;
  margin-top: 40px;
  .now-message {
    margin-bottom: 10px;
  }
}
.order-choose,
.package-info {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.order-price-info {
  position: relative;
  // width: 50%;
}
.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .label {
    width: 100px;
    text-align: right;
    padding-right: 10px;
    color: #888;
  }
  .form-control {
    padding-left: 10px;
    flex: 1;
    position: relative;
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
      &:hover {
        color: #222;
      }
      &:focus-within {
        outline: 3px solid fade(@primary-color, 6%);
      }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: @primary-color;
      border-color: @primary-color;
      color: #444;
    }
    .ant-radio-button-wrapper-checked {
      box-shadow: -1px 0 0 0 @primary-color;
    }
  }
  &.en {
    .label {
      width: 146px;
    }
    .ant-radio-group {
      font-size: 12px;
    }
  }
  .real-time-rate {
    color: #666;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
    left: 10px;
  }
  .num {
    // color: @red-color;
    &.large {
      font-size: 20px;
    }
  }
  .green {
    color: @green-color;
    font-size: 12px;
    em {
      color: #444;
    }
  }
}
.package-detail {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f1f1f1;
}
.package-info {
  padding: 0 10px 0 50px;
}
.custom-table {
  tr {
    td,
    th {
      padding-left: 10px;
      &:nth-of-type(1) {
        color: #888;
      }
      &:nth-of-type(3) {
        font-weight: bold;
        color: #f0ab39;
      }
    }
  }
}
.more-time {
  margin-left: 10px;
  color: #999;
  &.active {
    color: #f0ab39;
  }
  i {
    margin-left: 5px;
  }
  &:hover {
    color: #f0ab39;
  }
}
.ant-spin {
  position: absolute;
  z-index: 4;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 400px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
.middle {
  font-size: 18px;
  font-weight: 100 !important;
}
.package-num {
  display: flex;
  flex-direction: row;
}
/deep/ .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #fac92b;
}
.qrcode-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .wechat-log {
    width: 128px;
  }
  .qrcode-img {
    width: 250px;
  }
  .wechat-tips {
    width: 200px;
  }
}
.cs-qrcode {
  width: 150px;
  height: 150px;
}
.cs-tips {
  margin-left: 15px;
}
</style>
